export const f = (pixels, formatter) => {
    return pixels.map(formatter).join("\n");
};

export const facebook = (pixels) => {
    if (!pixels?.length) {
        return ``;
    }
    const js = f(pixels, (pixel) => `fbq('init', '${pixel}');`);
    const html = f(pixels, (pixel) => `<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=${pixel}&ev=PageView&noscript=1"/>`);

    return `<script type="text/javascript">
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    ${js}
</script>
<noscript>
    ${html}
</noscript>`;
};

export const snapchat = (pixels) => {
    if (!pixels?.length) {
        return ``;
    }
    const js = f(pixels,(pixel) => `snaptr('init', '${pixel}', {
        'user_email': 'unknown'
    });`);

    return `<script type='text/javascript'>
    (function(e,t,n){if(e.snaptr)return;var a=e.snaptr=function()
    {a.handleRequest?a.handleRequest.apply(a,arguments):a.queue.push(arguments)};
    a.queue=[];var s='script';r=t.createElement(s);r.async=!0;
    r.src=n;var u=t.getElementsByTagName(s)[0];
    u.parentNode.insertBefore(r,u);})(window,document,
    'https://sc-static.net/scevent.min.js');
    
    ${js}
    
</script>`;
};

export const gtm = (pixels) => {
    if (!pixels?.length) {
        return ``;
    }
    const js = f(pixels, (pixel) => `gtag('config', '${pixel}');`);
    const html = f(pixels, (pixel) => `<script async src="https://www.googletagmanager.com/gtag/js?id=${pixel}"></script>`);

    return `${html}
<script>
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    ${js}
</script>`;
};

export const twitter = (pixels) => {
    if (!pixels?.length) {
        return ``;
    }
    const js = f(pixels, (pixel) => `twq('init','${pixel}');`);

    return `<script type="text/javascript">
    !function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
    },s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='//static.ads-twitter.com/uwt.js',
    a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
    // Insert Twitter Pixel ID and Standard Event data below
    
    ${js}
    
</script>`;
};

export const tiktok = (pixels) => {
    if (!pixels?.length) {
        return ``;
    }
    const js = f(pixels, (pixel) => `ttq.load('${pixel}');`);

    return `<script>
    !function (w, d, t) {
    w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};
    ${js}
    }(window, document, 'ttq');
</script>`;
};
module.exports = {
    brandInfo: {
        phoneNumber: "15878870645",
        contactEmail: "info@carprogram.ca",
        address: `4915 54 Street Red Deer AB`,
        copyright: `© ${new Date().getFullYear()} Car Program`,
        defaultCampaignId: "217",
    },

    images: {
        logo: "images/logo.png",
        icon: "images/favicon.png",
    },

    project: "can-credit-application",
    name: "Car Program",
    description: "Get the best rates ever",

    siteUrl: "https://www.carprogram.ca/",
    homeUrl: "/",
    termsUrl: "/terms_of_use",
    privacyPolicyUrl: "/privacy_policy",
    successUrl: "/success",

    hideSuccessCtas: true,

    layout: {
        component: require.resolve("./src/layout/index.jsx"),
        theme: require.resolve("./themes/custom/single-page-theme.js"),
    },
};